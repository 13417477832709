$danger-color-light: #f87173;
$danger-color: #ff4d4f;
$grey-light: #ebedf0;
$grey: #000000a6;
$primary-color-light: #87dbd3;
$primary-color: #0eb8a8;
$transition-delay: 0.3s;
$transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);

:root {
  --danger-color-light: #f87173;
  --danger-color: #ff4d4f;
  --grey-light: #ebedf0;
  --grey: #000000a6;
  --primary-color-light: #87dbd3;
  --primary-color: #0eb8a8;
  --transition-delay: 0.3s;
  --transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  --warning-color-dark: #faad14;
  --warning-color-light: #fffbe6;
  --warning-color: #ffe58f;
}