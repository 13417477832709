@import '~normalize.css';
@import 'variable.scss';

body {
  overflow: hidden;
}

#root {
  background-color: #ffffff;
  color: var(--grey);
  height: 100%;
  position: relative;
  width: 100%;
}

::-webkit-scrollbar {
  width: 3px;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--grey-light);
}
 
::-webkit-scrollbar-thumb {
  background-color: adjust-color($grey, $lightness: 70%);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: adjust-color($grey, $lightness: 40%);
}